<template>
  <div>
    <eden-table-actions :title="title" @search="search">
      <template slot="actions">
        <service-operations-filter
          class="mr-10"
          :disabled="loading"
          :clear="filterParams.clear"
          @filter="filter"
        />
        <el-button
          type="plain"
          icon="eden-icon-upload"
          @click="exporting = true"
          :disabled="loading || !pageData.length"
        >
          Export
        </el-button>
      </template>
    </eden-table-actions>
    <template>
      <eden-loader v-if="loading" />
      <template v-else>
        <eden-filter-items
          v-if="filterParams.status"
          :params="filterParams.paramsLabel"
          @clear-filter="clearFilter"
          @clear-filters="clearFilters"
        />
        <el-collapse v-if="pageData.length" v-model="activeId" accordion>
          <el-table :data="pageData">
            <el-table-column width="100">
              <template slot="header">
                <span> Order Id </span>
              </template>
              <template v-slot="scope">
                <router-link
                  class="text-primary"
                  :to="{
                    name: 'orders.order',
                    params: { id: scope.row.id },
                  }"
                >
                  {{ scope.row.id }}</router-link
                >
              </template>
            </el-table-column>
            <el-table-column prop="name" width="150">
              <template slot="header">
                <span> Customer / Gardener </span>
              </template>
              <template v-slot="scope">
                <p class="text-grey-primary">
                  {{ formatName(scope.row.customer) }}
                </p>
                <span class="text-grey-tertiary">{{ scope.row.gardener }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="orderType" width="150">
              <template slot="header">
                <span> Order Type </span>
              </template>
              <template v-slot="scope">
                <p class="text-grey-primary">
                  {{ formatName(scope.row.order_type) }}
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="name" width="250">
              <template slot="header">
                <span> Menu Type</span>
              </template>
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.menu_type
                      .toLowerCase()
                      .includes('corporate standard')
                  "
                >
                  Corporate Standard
                </div>
                <div
                  v-else-if="
                    scope.row.menu_type
                      .toLowerCase()
                      .includes('individual standard')
                  "
                >
                  Individual Standard
                </div>
                <div
                  v-else-if="scope.row.menu_type.toLowerCase().includes('lite')"
                >
                  Lite
                </div>
                <div
                  v-else-if="
                    scope.row.menu_type.toLowerCase().includes('basic')
                  "
                >
                  Basic
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="items">
              <template slot="header">
                <span> Items </span>
              </template>
              <template v-slot="scope">
                <el-collapse-item
                  v-if="scope.row.meals.length > 1"
                  :name="scope.row.id"
                  class="plain"
                >
                  <template slot="title">
                    <p class="text-primary text-cursor font-base">
                      {{ scope.row.meals.length }} items
                    </p>
                  </template>
                  <div>
                    <span v-for="(item, index) in scope.row.meals" :key="index"
                      ><p>{{ formatMealName(item) }}</p></span
                    >
                  </div>
                </el-collapse-item>
                <p v-else>
                  {{ formatMealName(scope.row.meals[0]) }}
                </p>
              </template>
            </el-table-column>
            <el-table-column width="160">
              <template slot="header">
                <div class="table--header">
                  <span>Ordered At</span>
                </div>
              </template>
              <template slot-scope="scope">
                <el-tag type="success" class="text-normalize">
                  {{ formatDateAndTime(scope.row.created_at, "do m,y") }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="date" width="120">
              <template slot="header">
                <span> Delivery Date </span>
              </template>
              <template v-slot="scope">
                <p>
                  {{
                    formatDate(scope.row.service_delivery_date, "ddd do, m, y")
                  }}
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="status" width="120">
              <template slot="header">
                <span> Status </span>
              </template>
              <template v-slot="scope">
                <el-tag :type="setType(scope.row.completed_status)">{{
                  formatText(scope.row.completed_status)
                }}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse>
        <eden-content-empty v-else :text="'No orders'" />
      </template>
    </template>
    <service-partner-schedule-export
      :show.sync="exporting"
      :period="period"
      :custom="custom"
      :data="items"
      :data-time="pageDataTime"
    />
  </div>
</template>

<script>
import servicepartners from "@/requests/serviceoperations/servicepartners";
import ServiceOperationsFilter from "@/components/ServiceOperations/ServiceOperationsFilter";
import ServicePartnerScheduleExport from "./ServicePartnerScheduleExport.vue";

export default {
  name: "ServicePartnerSchedule",
  components: { ServiceOperationsFilter, ServicePartnerScheduleExport },
  data() {
    return {
      loading: false,
      activeId: null,
      searchQuery: "",
      period: "tomorrow",
      periods: {
        today: "Today",
        tomorrow: "Tomorrow",
        thisweek: "This week",
      },
      pageData: [],
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      pageDataTime: "",
      custom: {
        from: null,
        to: null,
      },
      exporting: false,
    };
  },
  computed: {
    servicePartnerId() {
      return this.$route.params.id;
    },
    title() {
      return `${this.pageData.length} Orders`;
    },
    items() {
      const query = this.formatQueryText(this.searchQuery);

      if (query !== "") {
        this.mixpanelTrack({ event: "scheduler_partner_search" });
        return this.pageData.filter((item) => {
          return item.customer.toLowerCase().includes(query);
        });
      } else {
        return this.pageData;
      }
    },
  },
  created() {
    this.getSchedule({ period: this.period });
  },
  methods: {
    search(query) {
      this.searchQuery = query;
    },
    filter({ params, paramsLabel }) {
      this.loading = true;
      let stringedParams = `?${this.sortObjectAsParams(params)}`;
      if (!stringedParams.includes("from")) {
        stringedParams = `?${this.sortObjectAsParams(params)}&period=${
          this.period
        }`;
      }
      if (this.sortObjectAsParams(params).includes("same-day-delivery")) {
        stringedParams = `?filter=one-time&period=today`;
      }
      servicepartners
        .orderFilter(this.servicePartnerId, stringedParams)
        .then((response) => {
          const { status, data } = response;
          if (status) {
            this.filterParams = {
              status: true,
              params: params,
              paramsLabel: paramsLabel,
            };
            this.pageData = this.formatSchedule(data.data);
            this.pageDataTime = new Date();
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.response.data.message);
        });
    },

    getSchedule({ period, from, to }) {
      this.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      console.log(start_date, end_date )
      servicepartners
        .schedule(this.servicePartnerId, start_date, end_date)
        .then((response) => {
          const { status, data } = response;

          if (status) {
            this.pageData = this.formatSchedule(data.data);
            this.pageDataTime = new Date();
            this.period = period;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    formatSchedule(orders) {
      if (!orders) {
        return [];
      }
      return orders.map((order) => {
        return {
          ...order,
          id: order.order_id,
          customer: order.customer ? this.formatName(order.customer) : "-",
          meals: order.items_assigned_to_order,
        };
      });
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;

      if (Object.keys(keys).length) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.clearFilters();
      }
    },
    clearFilters() {
      this.filterParams = {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      };
      this.getSchedule({ period: this.period });
    },
  },
};
</script>

<style lang="scss" scoped>
.items-expand {
  span {
    display: inline-block;
    margin-top: 10px;
    font-size: 0.875rem;
    color: var(--eden-grey-tertiary);
  }
}
</style>
