<template>
  <div>
    <div class="service-partner-profile-summary">
      <eden-information-card :title="'General Information'">
        <template slot="content">
          <el-table
            :class="'general'"
            :data="general"
            :show-header="false"
            :span-method="arraySpanMethod"
          >
            <el-table-column>
              <template slot-scope="scope">
                <template v-if="scope.$index < 2">
                  <span class="label font-xsm">{{ scope.row.label }}</span>
                  <p class="text font-sm">
                    {{ scope.row.text }}
                  </p>
                </template>
                <template v-else>
                  <el-table
                    :class="'inner-table'"
                    :data="scope.row.data"
                    :show-header="false"
                  >
                    <el-table-column>
                      <template slot-scope="scope">
                        <span class="label font-xsm">{{
                          scope.row.label
                        }}</span>
                        <p class="text font-sm">
                          {{ scope.row.text }}
                        </p>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </template>
            </el-table-column></el-table
          >
        </template>
      </eden-information-card>

      <eden-information-card :title="'Bank Account'">
        <template slot="content">
          <el-table :data="bank" :show-header="false">
            <el-table-column>
              <template slot-scope="scope">
                <span class="label font-xsm">{{ scope.row.label }}</span>
                <p v-if="scope.row.text" class="text font-sm">
                  {{ scope.row.text }}
                </p>
                <p v-else>-</p>
              </template>
            </el-table-column></el-table
          >
        </template>
      </eden-information-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicePartnerProfileSummary",
  props: {
    servicePartner: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      general: [
        {
          label: "Email",
          text: this.servicePartner.email,
        },
        {
          label: "Address",
          text: this.servicePartner.address || "-",
        },

        {
          data: [
            {
              label: "Joined on",
              text:
                this.formatDate(this.servicePartner.created_at, "do m, y") ||
                "-",
            },
            {
              label: "Last updated",
              text:
                this.formatDate(this.servicePartner.updated_at, "do m, y") ||
                "-",
            },
          ],
        },
      ],
      bank: [
        {
          label: "Bank",
          text: this.servicePartner.bank_name || "-",
        },
        {
          label: "Account Number",
          text: this.servicePartner.bank_account_no || "-",
        },
        {
          label: "Phone number",
          text: this.formatPhoneNumber(this.servicePartner.phone_number) || "-",
        },
      ],
    };
  },
  methods: {
    arraySpanMethod({ rowIndex }) {
      if (rowIndex === 2) {
        return [1, 2];
      } else if (rowIndex === 3) {
        return [3, 4];
      } else {
        return [1, 4];
      }
    },
  },
};
</script>

<style lang="scss">
.service-partner-profile-summary {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.general {
  tr:last-child {
    td {
      padding: 0;

      .cell {
        padding: 0;
      }
    }
  }
}

.inner-table {
  tbody {
    display: flex;

    tr {
      width: 50%;
      padding: 12px;
      &:nth-child(2) {
        border-left: 1px solid #ebeef5;
      }
    }
  }
}

@media (min-width: 768px) {
  .information-card {
    margin-bottom: 0 !important;
  }
  .information-card:first-child {
    border-radius: 0;
  }
  .information-card:nth-child(2) {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@media (min-width: 996px) {
  .service-partner-profile-summary {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
