<template>
  <div>
    <el-dialog
      title="Export"
      :visible.sync="shouldShow"
      @close="closeEvent"
      width="30%"
    >
      <eden-file-export
        :data="exportData"
        @exported="closeEvent"
        @cancelled="closeEvent"
      >
        <el-form label-position="top" :model="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select the data you'd like to export">
                <el-checkbox-group v-model="form.fields">
                  <el-checkbox
                    v-for="(value, key, i) in fields"
                    :key="i"
                    :disabled="
                      key === 'items_assigned_to_order' ||
                      key === 'service_delivery_date'
                    "
                    :label="key"
                  >
                    {{ value }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select data format">
                <el-radio-group v-model="form.format">
                  <el-radio
                    v-for="(key, value, i) in formats"
                    :key="i"
                    :label="value"
                  >
                    {{ key }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button
            type="primary"
            :loading="exporting"
            @click="getExportData"
            :disabled="!form.format || !form.fields.length"
            >Export</el-button
          >
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ServicePartnerScheduleExport",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    period: {
      type: String,
      default: "",
    },
    custom: {
      type: Object,
      default() {
        return {};
      },
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    dataTime: {
      type: [Date, String],
      default: "",
    },
  },

  data() {
    return {
      fields: {
        items_assigned_to_order: "Food Item",
        service_delivery_date: "Delivery Date",
        order_type: "Order Type",
      },
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xls: "Excel",
      },
      form: {
        fields: ["items_assigned_to_order", "service_delivery_date"],
        format: "",
      },
      exportData: {},
      exporting: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },
  methods: {
    closeEvent() {
      this.form.fields = ["items_assigned_to_order", "service_delivery_date"];
      this.form.format = "";
      this.exporting = false;
      this.shouldShow = false;
    },

    getExportData() {
      this.loading = true;
      this.formatExportData(this.data);
      this.mixpanelTrack({
        event: "schedule_export",
      });
    },

    formatExportData(data) {
      let updated = this.formatDateAndTime(this.dataTime, "do m, y");
      let date = this.formatDateAndTime(new Date(), "do m, y");
      let title = `Schedule \nDownloaded at - ${updated}\nExported at - ${date}`;

      let headers = {
        sn: "S/N",
      };

      let scheduleData = [];
      this.form.fields.forEach((key) => (headers[key] = this.fields[key]));

      scheduleData = data.map((item, index) => {
        let export_object = {};
        Object.keys(headers).forEach((key) => {
          switch (key) {
            case "sn":
              export_object[key] = index + 1;
              break;
            case "items_assigned_to_order":
              export_object[key] =
                item.meals.length > 0
                  ? item.meals
                      ?.map((ml) => {
                        if (typeof ml === "string") {
                          return ml;
                        } else {
                          return ml?.specifics;
                        }
                      })
                      .join(" | ")
                  : "-";
              break;
            case "service_delivery_date":
              export_object[key] = this.formatDate(
                item.service_delivery_date,
                "ddd do, m, y",
              );
              break;
            case "order_type":
              export_object[key] = item.order_type;
              break;
            default:
              break;
          }
        });
        return export_object;
      });
      this.exportData = {
        title,
        headers,
        data: scheduleData,
        fileName: "Schedule",
        titleMargin: 44,
        format: this.form.format,
      };

      this.exporting = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.downloadComboCsv,
.downloadComboXls {
  position: absolute;
  opacity: 0;
}
</style>
