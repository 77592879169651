<template>
  <div>
    <eden-table-actions :title="title" @search="search">
      <template slot="actions">
        <service-operations-filter class="mr-10" :disabled="loading" :clear="filterParams.clear" @filter="filter" />
        <el-button type="plain" icon="eden-icon-upload" @click="exporting = true" :disabled="loading || !pageData.length">
          Export
        </el-button>
      </template>
    </eden-table-actions>
    <template>
      <eden-loader v-if="loading" />
      <template v-else>
        <eden-filter-items v-if="filterParams.status" :params="filterParams.paramsLabel" @clear-filter="clearFilter"
          @clear-filters="clearFilters" />
        <template v-if="items.length">
          <el-table :data="items" :key="sort.property">
            <el-table-column width="550">
              <template slot="header">
                <eden-table-column-header :label="'Food Item'" :property="'name'" :sort-property="sort.property"
                  @sort="sortItems($event, 'name')" />
              </template>
              <template slot-scope="scope">
                <span class="font-sm">
                  {{ formatMealName(formatMealNameWithType(scope.row.name)) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <eden-table-column-header :label="'Menu Type'" :property="'menu_type'" :sort-property="sort.property"
                  @sort="sortItems($event, 'menu_type')" />
              </template>
              <template slot-scope="scope">
                <div v-if="scope.row.name.toLowerCase().includes('corporate standard')
                  ">
                  Corporate Standard
                </div>
                <div v-else-if="scope.row.name.toLowerCase().includes('individual standard')
                  ">
                  Individual Standard
                </div>
                <div v-else-if="scope.row.name.toLowerCase().includes('lite')">
                  Lite
                </div>
                <div v-else-if="scope.row.name.toLowerCase().includes('basic')">
                  Basic
                </div>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <eden-table-column-header :label="'Order Type'" :property="'order_type'" :sort-property="sort.property"
                  @sort="sortItems($event, 'order_type')" />
              </template>
              <template slot-scope="scope">
                <span class="text-grey-primary">
                  {{ formatName(scope.row.order_type) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <eden-table-column-header :label="'Count'" :property="'count'" :sort-property="sort.property"
                  @sort="sortItems($event, 'count')" />
              </template>
              <template slot-scope="scope">
                <span class="font-sm">
                  {{ scope.row.count }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <eden-content-empty v-else :text="'No items'" />
      </template>
    </template>
    <production-provisions-export :show.sync="exporting" :period="period" :custom="custom" :data="items"
      :data-time="pageDataTime" />
  </div>
</template>

<script>
import { formatItems } from "../format-items";
import * as actions from "@/store/action-types";
import kitchen from "@/requests/stewards/kitchen";
import ServiceOperationsFilter from "@/components/ServiceOperations/ServiceOperationsFilter";
import ProductionProvisionsExport from "./ProductionProvisionsExport.vue";

export default {
  name: "ProductionProvisionItems",
  components: {
    ProductionProvisionsExport,
    ServiceOperationsFilter,
  },
  data() {
    return {
      servicePartnerId: this.getDefaultSp("meal"),
      loading: false,
      searchQuery: "",
      period: "tomorrow",
      periods: {
        today: "Today",
        tomorrow: "Tomorrow",
        thisweek: "This week",
      },
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      custom: {
        from: null,
        to: null,
      },
      pageData: [],
      sort: {
        property: "name",
        direction: "asc",
      },
      pageDataTime: "",
      exporting: false,
    };
  },
  computed: {
    title() {
      return `${this.items.length} Items`;
    },
    items() {
      const query = this.formatQueryText(this.searchQuery);

      if (query !== "") {
        this.mixpanelTrack({ event: "production_provisions_search" });
        return this.pageData.filter((item) => {
          return item.name.toLowerCase().includes(query);
        });
      } else {
        return this.pageData;
      }
    },
  },
  created() {
    this.getItems({ period: this.period });
  },
  methods: {
    filter({ params, paramsLabel }) {
      this.loading = true;
      let stringedParams = `?${this.sortObjectAsParams(params)}`;

      if (!stringedParams.includes("from")) {
        stringedParams = `?${this.sortObjectAsParams(params)}&period=${this.period}`;
      }
      if (stringedParams.includes("from")) {
        this.custom.from = params.from;
        this.custom.to = params.to;
      }

      if (stringedParams.includes("same-day-delivery")) {
        stringedParams = stringedParams.replace("same-day-delivery", "1");
        // Remove any occurrence of period parameter
        stringedParams = stringedParams.replace(/&?period=[^&]+/g, "");
        stringedParams += "&period=today";
      }

      if (stringedParams.includes("filter[one-time]=one-time")) {
        stringedParams = stringedParams.replace("filter[one-time]=one-time", "filter[one-time]=1");
        console.log(stringedParams, "ini6")
      }
      stringedParams = stringedParams.replace(/\[\]/g, "");
      kitchen
        .filter(this.servicePartnerId, stringedParams)
        .then((response) => {
          const { status } = response;
          if (status) {
            this.filterParams = {
              status: true,
              params: params,
              paramsLabel: paramsLabel,
            };
            const meals = response.data.data;
            this.pageData = formatItems(meals);
            this.pageDataTime = new Date();
            const { direction, property } = this.sort;
            if (property) {
              this.sortItems(direction, property);
            }
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.response.data.message);
        });
    },
    getItems({ period, from, to }) {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_PRODUCTION_PROVISIONS, {
          spId: this.servicePartnerId,
          period: period,
          from: from ? from : null,
          to: to ? to : null,
        })
        .then((response) => {
          if (response.data.status) {
            this.period = period;
            // if (period === "custom") {
            //   this.period = period;
            //   this.custom.from = from;
            //   this.custom.to = to;
            // }
            this.mixpanelTrack({
              event: `production_provisions_filter_${period}`,
            });
            this.setItems();
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    search(query) {
      this.searchQuery = query;
    },
    setItems() {
      const mealItems = this.$store.getters.production_provisions.data;
      this.pageData = formatItems(mealItems);
      this.pageDataTime = new Date();
      const { direction, property } = this.sort;
      if (property) {
        this.sortItems(direction, property);
      }
    },
    sortItems(direction, property) {
      this.sort.property = property;
      this.sort.direction = direction;
      this.pageData = this.sortList(this.pageData, property, direction);
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;

      if (Object.keys(keys).length) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.clearFilters();
      }
    },
    clearFilters() {
      this.filterParams = {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      };
      this.getItems({ period: this.period });
    },
  },
};
</script>
