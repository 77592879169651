<template>
  <div class="service-partner">
    <div v-if="loading">Loading</div>
    <template v-else>
      <eden-page-header :title="'Service Partners'" :subtitle="name" />
      <div class="service-partner__profile">
        <eden-information-card :class="'main'">
          <template slot="content">
            <div class="main--information">
              <img
                :src="servicePartner?.avatar || getAvatar()"
                alt="avatar"
                class="avatar-img"
              />
              <h6 class="font-lg name">{{ name }}</h6>
              <div class="profile-tags flex align-center justify-center">
                <el-tag :type="setServiceType(service)">{{ service }}</el-tag>
                <el-tag :type="setType(status)">{{
                  formatText(status)
                }}</el-tag>
              </div>

              <el-button type="plain" @click="editServicePartner"
                >Edit Profile</el-button
              >
            </div>
          </template>
        </eden-information-card>
        <service-partner-profile-summary :service-partner="servicePartner" />
      </div>
      <div>
        <el-tabs v-model="tab" @tab-click="updateRoute">
          <el-tab-pane label="Schedule" name="schedule">
            <service-partner-schedule />
          </el-tab-pane>
          <el-tab-pane
            label="Production Provisions"
            name="production-provisions"
          >
            <production-provisions />
          </el-tab-pane>
          <el-tab-pane label="Combo Check" name="combo-check">
            <combo-check-items :allow-marking="false" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </template>
  </div>
</template>

<script>
import ServicePartnerProfileSummary from "@/components/ServiceOperations/ServicePartners/ServicePartnerProfileSummary";
import ProductionProvisions from "@/components/Stewards/ProductionProvisions/ProductionProvisions";
import ComboCheckItems from "@/components/Stewards/ComboCheck/ComboCheckItems";

import servicepartners from "@/requests/serviceoperations/servicepartners";
import ServicePartnerSchedule from "@/components/ServiceOperations/ServicePartners/ServicePartnerSchedule";

export default {
  name: "ServicePartner",
  components: {
    ServicePartnerSchedule,
    ServicePartnerProfileSummary,
    ProductionProvisions,
    ComboCheckItems,
  },
  data() {
    return {
      loading: false,
      tab: "schedule",
      servicePartner: {},
    };
  },
  computed: {
    servicePartnerId() {
      return this.$route.params.id;
    },
    name() {
      return this.servicePartner?.name || "-";
    },
    status() {
      return this.servicePartner?.active_status ? "active" : "inactive";
    },
    service() {
      return this.servicePartner?.service_offering;
    },
  },
  created() {
    this.getServicePartner();
    let { t } = this.$route.query;
    this.tab = t ? t : "schedule";
  },
  methods: {
    updateRoute(tab) {
      const { t } = this.$route.query;

      if (t === this.tab) {
        return false;
      }
      this.$router.push({
        name: "serviceoperations.partners.partner",
        query: { t: tab.name },
        params: { id: this.$route.params.id },
      });
    },
    getServicePartner() {
      this.loading = true;
      servicepartners
        .get(this.servicePartnerId)
        .then((response) => {
          this.servicePartner = response.data.partners;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    editServicePartner() {
      this.$router.push({
        name: "serviceoperations.partners.partner-edit",
        params: { id: this.servicePartnerId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.service-partner {
  &__profile {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 44px;

    .main--information {
      margin-bottom: 30px;
      text-align: center;

      .name {
        font-weight: 400;
      }

      .email {
        font-weight: 400;
        margin-bottom: 8px;
      }

      .profile-tags {
        margin: 8px 0 28px 0;
      }
      .el-button {
        margin: auto;
      }
    }
  }
}

@media (min-width: 768px) {
  .information-card {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;

    &.main {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }
  }
}

@media (min-width: 996px) {
  .service-partner__profile {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 0;
  }
}
</style>
