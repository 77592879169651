<template>
  <div>
    <el-dialog title="Export" :visible.sync="shouldShow" @close="closeEvent" width="30%">
      <eden-file-export :data="exportData" @exported="closeEvent" @cancelled="closeEvent">
        <el-form label-position="top" :model="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select the data you'd like to export">
                <el-checkbox-group v-model="form.fields">
                  <el-checkbox v-for="(value, key, i) in fields" :key="i" :label="key"
                    :disabled="key === 'food_item' || key === 'count'">
                    {{ value }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select data format">
                <el-radio-group v-model="form.format">
                  <el-radio v-for="(key, value, i) in formats" :key="i" :label="value">
                    {{ key }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button type="primary" :loading="exporting" @click="getExportData"
            :disabled="!form.format || !form.fields.length">Export</el-button>
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ProductionProvisionExport",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    period: {
      type: String,
      default: "",
    },
    custom: {
      type: Object,
      default() {
        return {};
      },
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    dataTime: {
      type: [Date, String],
      default: "",
    },
  },

  data() {
    return {
      fields: {
        food_item: "Food Item",
        count: "Count",
        order_type: "Order Type",
      },
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xls: "Excel",
      },
      form: {
        fields: ["food_item", "count", "order_type"],
        format: "",
      },
      exportData: {},
      exporting: false,
    };
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },
  methods: {
    closeEvent() {
      this.form.format = "";
      this.exporting = false;
      this.shouldShow = false;
    },

    getExportData() {
      this.exporting = true;
      this.formatExportData(this.data);
      this.mixpanelTrack({ event: "production_provisions_export" });
    },

    formatExportData(data) {
      let from = this.custom.from
        ? this.formatDate(this.custom.from, "do m, y")
        : null;
      let to = this.custom.to
        ? this.formatDate(this.custom.to, "do m, y")
        : null;
      let period;
      if (this.custom) {
        period = `${from} - ${to}`;
      } else {
        const fPeriod = this.formatPeriodDate(this.period, "do m, y");
        period = fPeriod.start ? `${fPeriod.start} - ${fPeriod.end}` : fPeriod;
      }
      let updated = this.formatDateAndTime(this.dataTime, "do m, y");
      let exported = this.formatDateAndTime(new Date(), "do m, y");
      let title = `Production Provisions - ${period}\nDownloaded at - ${updated}\nExported at - ${exported}`;
      let headers = {
        sn: "S/N",
      };
      let extra_headers = {};
      let provisionData = [];
      this.form.fields.forEach(
        (key) => (extra_headers[key] = this.fields[key])
      );
      headers = { ...headers, ...extra_headers };
      provisionData = data.map((item, index) => {
        return {
          sn: index + 1,
          food_item: this.formatName(item.name),
          count: item.count,
          order_type: item.order_type
        };
      });
      this.exportData = {
        title,
        headers,
        data: provisionData,
        fileName: "Production Provisions",
        titleMargin: 44,
        format: this.form.format,
      };
      this.exporting = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
